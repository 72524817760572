<template>
	<div class="container">
		<div class="code-wrap flex-column" v-if="showVerify">
			<div class="code-title">请输入验证码</div>
			<img :src="base64code" @click.stop="getVerifyCode" alt="" class="code-img">
			<div class="code-tips">看不清？点击图片换一张</div>
			<div class="code-input-wrap">
				<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
					v-model="verifyCode" placeholder="请输入验证码" />
			</div>
			<div class="send-code-btn" @click="sendCode()">确定</div>
		</div>
		<div class="title">账户登录</div>
		<div class="phone-title">手机号验证</div>
		<div class="form-wrap">
			<div class="form-item">
				<div class="form-ti">请输入您的手机号码</div>
				<input @input="inputLoginMobile" oninput="if(value.length > 11)value = value.slice(0, 11)"
					maxlength="11" v-model="mobile" class="input-mobile" type="number" placeholder="手机号码">
			</div>
			<div class="form-item form-mt">
				<div class="form-ti">请输入验证码</div>
				<div class="flex-row align-center">
					<input oninput="if(value.length > 6)value = value.slice(0, 6)" v-model="code" maxlength="6"
						class="input-code" type="number" placeholder="验证码">
					<div class="code-btn" :class="canSend==0?'code-btn-false':''" @click.stop="getVerifyCode()">{{msg}}
					</div>
				</div>
			</div>
		</div>
		<div class="login-btn" :class="canLogin == 1 && code.length >=6?'':'login-btn-false'" @click.stop="doLogin()">登录
		</div>
	</div>
</template>

<script>
	import config from '../config/config.js';
	import {
		Toast,
	} from 'vant';
	import 'vant/lib/toast/index.css'
	export default {
		data() {
			return {
				showVerify: 0,
				mobile: '',
				code: '',
				time: 0,
				msg: '获取验证码',
				canSend: 0,
				sending: 0,
				logining: 0,
				interval: '',
				base64code: '',
				verifyKey: '',
				verifyCode: '',
				arg: 0,
				canLogin: 0
			}
		},
		methods: {
			doLogin() {
				const that = this;
				if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(that.mobile)) {
					Toast('请输入手机号')
					return;
				}
				if (that.code.length < 6) {
					Toast('请输入验证码')
					return;
				}
				if (!that.logining) {
					let params = new FormData()
					params.append('phone', that.mobile)
					params.append('verifyCode', that.code)
					params.append('openId', '')
					that.logining = 1;
					Toast.loading({
						message: '登录中...',
						forbidClick: true,
						duration: 0,
					});
					this.axios.post(config.requestUrl + '/front/weChatLogin', params, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).then(response => {
						if (response.data.code == 0) {
							Toast.success('登录成功');
							let user = response.data.obj;
							this.$store.commit('setUser', user)

							this.$store.commit('clearCode')
							localStorage.setItem('user', JSON.stringify(user))
							that.logining = 0;
							// this.$router.replace('/invoice')
							that.$router.go(-1)
						} else {
							Toast(response.data.msg);
						}
					}, response => {
						console.log(response)
						that.logining = 0;
						Toast('登录失败，请稍后重试');
					})
				}
			},
			inputLoginMobile(e) {
				this.checkLoginCanSend();
			},
			checkLoginCanSend() {
				if ((/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.mobile)) && this.time == 0) {
					this.canSend = 1;
				}
				if (/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.mobile)) {
					this.canLogin = 1;
				} else {
					this.canLogin = 0
				}

			},
			getVerifyCode() {
				const that = this;
				if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(that.mobile)) {
					Toast('请输入手机号')
					return;
				}
				let params = new FormData()
				this.axios.post(config.requestUrl + '/search/getImage', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.showVerify = 1;
						that.verifyKey = response.data.obj.key;
						that.base64code = response.data.obj.data
						console.log(response)
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			sendCode() {
				const that = this;
				if (that.verifyCode == '') {
					Toast('请输入验证码')
					return;
				}
				this.showVerify = 0;
				let params = new FormData()
				params.append('phoneEmail', that.mobile)
				params.append('smsType', 1)
				params.append('zone', 86)
				params.append('doType', 2)
				params.append('codeValue', that.verifyCode)
				params.append('key', that.verifyKey)
				if (!that.sending) {
					that.sending = 1;
					Toast.loading({
						message: '发送中...',
						forbidClick: true,
						duration: 0,
					});
					that.axios.post(config.requestUrl + '/ajax/sendCode', params, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).then(response => {
						that.sending = 0;
						if (response.data.code == 0) {
							Toast.success('发送成功');
							that.time = 60;
							that.canSend = false;
							that.interval = setInterval(() => {
								if (that.time <= 0) {
									that.msg = '获取验证码'
									clearInterval(this.interval);
									that.checkLoginCanSend();
								} else {
									that.time = that.time - 1;
									that.msg = `${that.time}s`
								}
							}, 1000)
						} else {
							Toast(response.data.msg);
						}

					}, response => {
						console.log(response)
						that.sending = 0;
						Toast('获取失败，请稍后重试');
					})

				}
			}
		},
		mounted() {
			// if (this.$store.state.user.token) {
			// 	this.$router.replace('/invoice');
			// }
			// console.log(document.body.clientWidth);
		}
	}
</script>
<style scoped="scoped">
	.title {
		width: 1920px;
		height: 133px;
		margin: auto;
		background: rgba(0, 0, 0, 0.02);
		font-size: 27px;
		font-weight: 600;
		color: #000000;
		text-align: center;
		line-height: 133px;
	}

	.phone-title {
		width: 1280px;
		height: 115px;
		margin: 90px auto;
		background: #FAFAFA;
		border-radius: 13px;
		font-size: 29px;
		font-weight: 600;
		color: #FF7400;
		text-align: center;
		line-height: 115px;
	}

	.form-wrap {
		width: 720px;
		margin: 110px auto 0;
	}

	.form-item {}

	.form-ti {
		font-size: 27px;
		font-weight: 400;
		color: #131313;
		margin-bottom: 27px;
	}

	.input-mobile {
		width: 720px;
		height: 80px;
		padding: 0 20px;
		box-sizing: border-box;

		background: #F6F7FB;
		border-radius: 13px;
		font-size: 27px;
	}

	.input-code {
		width: 493px;
		height: 80px;
		padding: 0 20px;

		box-sizing: border-box;
		background: #F6F7FB;
		border-radius: 13px;
		font-size: 27px;
	}

	.code-btn {
		width: 213px;
		height: 80px;
		margin-left: 13px;
		background: #5850EC;
		border-radius: 13px;
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 80px;
	}

	.code-btn-false {
		background: rgba(0, 0, 0, 0.1);
		color: #000000;
	}

	.form-mt {
		margin-top: 53px;
	}

	input {
		border: none;
	}

	.login-btn {
		width: 720px;
		height: 80px;
		margin: 80px auto 0;
		background: #5850EC;
		border-radius: 13px;
		font-size: 24px;
		color: #FFFFFF;
		text-align: center;
		line-height: 80px;
	}

	.login-btn-false {
		background: rgba(0, 0, 0, 0.1);
		color: #000000;
	}

	/* 蒙层 */
	.verify-model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1001;
	}

	.code-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1002;
		background: #FFFFFF;
		width: 500px;
		height: max-content;
		border-radius: 10px;
		padding: 40px 30px;
		box-sizing: border-box;
	}

	.code-title {
		font-size: 30px;
		font-weight: bold;
		text-align: center;
	}

	.code-img {
		width: 400px;
		height: 200px;
		display: flex;
		margin: 20px auto 15px;
	}

	.code-tips {
		width: 100%;
		text-align: center;
		font-size: 22px;
		color: #999999;
	}

	.code-input-wrap {
		width: 440px;
		height: 100px;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		padding-left: 30px;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.code-input-wrap input {
		width: 380px;
		height: 94px;
		font-size: 26px;
		color: #000000;
		line-height: 40px;
		padding: 0 30px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border: none;
		box-sizing: border-box;
	}

	.send-code-btn {
		margin-top: 80px;
		width: 440px;
		height: 80px;
		background: #6236FF;
		border-radius: 40px;
		font-size: 30px;
		color: #FFFFFF;
		letter-spacing: 2px;
		text-align: center;
		line-height: 80px;
	}

	.send-code-btn-true {
		color: #6236FF;
	}

	.get-code-false {
		color: rgba(0, 0, 0, 0.5);
	}
</style>
